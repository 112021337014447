import moment from "moment";
const setformatiaq = (data) => {

  var count = data["data"]["co2"].length;

  // reverse array
  data["data"]["pm25"] = data["data"]["pm25"].reverse();
  data["data"]["pm10"] = data["data"]["pm10"].reverse();
  data["data"]["co2"] = data["data"]["co2"].reverse();
  data["data"]["tvoc"] = data["data"]["tvoc"].reverse();
  data["data"]["rh"] = data["data"]["rh"].reverse();
  data["data"]["temp"] = data["data"]["temp"].reverse();
  // reverse array
  
  const set_data = [];

  for (let i = 0; i < count; i++) {
    var stap_status = 0;
    const format_new = {
      pm25: "",
      pm10: "",
      co2: "",
      tvoc: "",
      humidity: "",
      temprtature: "",
      last_time: "",
      location: "",
      color: "",
      text: "",
      colorPm25: "",
      colorPm10: "",
      colorCo2: "",
      colorTvoc: "",
    };

    format_new["pm25"] = data["data"]["pm25"][i]["value"];
    format_new["pm10"] = data["data"]["pm10"][i]["value"];
    format_new["co2"] = data["data"]["co2"][i]["value"];
    format_new["tvoc"] = data["data"]["tvoc"][i]["value"];
    format_new["humidity"] = parseInt(data["data"]["rh"][i]["value"]);
    format_new["temprtature"] = parseInt(data["data"]["temp"][i]["value"]);
    format_new["last_time"] = moment(data["data"]["pm25"][i]["time"]).format(
      "h:mm A"
    );
    format_new["location"] = data["SelectedBy"];

    // start CO2
    if (format_new["co2"] > 2000) {
      format_new["color"] = "#5d0494";
      format_new["text"] = "Unhealthy";
      format_new["colorCo2"] = "#5d0494";
      stap_status = 5;
    } else if (format_new["co2"] >= 1501 && format_new["co2"] <= 2000) {
      format_new["color"] = "#EA3223";
      // format_new["text"] = "Unhealthy for sensitive";
      format_new["text"] = "Sensitive";
      format_new["colorCo2"] = "#EA3223";
      stap_status = 4;
    } else if (format_new["co2"] >= 1001 && format_new["co2"] <= 1500) {
      format_new["color"] = "#F47C0D";
      format_new["text"] = "Moderate";
      format_new["colorCo2"] = "#F47C0D";
      stap_status = 3;
    } else if (format_new["co2"] >= 801 && format_new["co2"] <= 1000) {
      format_new["color"] = "#F6BF42";
      format_new["text"] = "Good";
      format_new["colorCo2"] = "#F6BF42";
      stap_status = 2;
    } else if (format_new["co2"] < 800) {
      format_new["color"] = "#67AC5B";
      format_new["text"] = "Excellent";
      format_new["colorCo2"] = "#67AC5B";
      stap_status = 1;
    }
    // end CO2

    // start TVOC
    if (format_new["tvoc"] > 2200) {
      format_new["color"] = "#5d0494";
      format_new["text"] = "Unhealthy";
      format_new["colorTvoc"] = "#5d0494";
      stap_status = 5;
    } else if (format_new["tvoc"] >= 661 && format_new["tvoc"] <= 2200) {
      format_new["colorTvoc"] = "#EA3223";
      if (stap_status <= 4) {
        format_new["color"] = "#EA3223";
        format_new["text"] = "Sensitive";
        stap_status = 4;
      }
    } else if (format_new["tvoc"] >= 221 && format_new["tvoc"] <= 660) {
      format_new["colorTvoc"] = "#F47C0D";
      if (stap_status <= 3) {
        format_new["color"] = "#F47C0D";
        format_new["text"] = "Moderate";
        stap_status = 3;
      }
    } else if (format_new["tvoc"] >= 66 && format_new["tvoc"] <= 220) {
      format_new["colorTvoc"] = "#F6BF42";
      if (stap_status <= 2) {
        format_new["color"] = "#F6BF42";
        format_new["text"] = "Good";
        stap_status = 2;
      }
    } else if (format_new["tvoc"] >= 0 && format_new["tvoc"] <= 65) {
      format_new["colorTvoc"] = "#67AC5B";
      if (stap_status <= 1) {
        format_new["color"] = "#67AC5B";
        format_new["text"] = "Excellent";
        stap_status = 1;
      }
    }
    // end TVOC

    // start PM10
    if (format_new["pm10"] > 350) {
      format_new["color"] = "#5d0494";
      format_new["text"] = "Unhealthy";
      format_new["colorPm10"] = "#5d0494";
      stap_status = 5;
    } else if (format_new["pm10"] >= 251 && format_new["pm10"] <= 350) {
      format_new["colorPm10"] = "#EA3223";
      if (stap_status <= 4) {
        format_new["color"] = "#EA3223";
        format_new["text"] = "Sensitive";
        stap_status = 4;
      }
    } else if (format_new["pm10"] >= 101 && format_new["pm10"] <= 250) {
      format_new["colorPm10"] = "#F47C0D";
      if (stap_status <= 3) {
        format_new["color"] = "#F47C0D";
        format_new["text"] = "Moderate";
        stap_status = 3;
      }
    } else if (format_new["pm10"] >= 51 && format_new["pm10"] <= 100) {
      format_new["colorPm10"] = "#F6BF42";
      if (stap_status <= 2) {
        format_new["color"] = "#F6BF42";
        format_new["text"] = "Good";
        stap_status = 2;
      }
    } else if (format_new["pm10"] < 50) {
      format_new["colorPm10"] = "#67AC5B";
      if (stap_status <= 1) {
        format_new["color"] = "#67AC5B";
        format_new["text"] = "Excellent";
        stap_status = 1;
      }
    }
    // end PM10

    // start PM25
    if (format_new["pm25"] > 150) {
      format_new["color"] = "#5d0494";
      format_new["text"] = "Unhealthy";
      format_new["colorPm25"] = "#5d0494";
      stap_status = 5;
    } else if (format_new["pm25"] >= 66 && format_new["pm25"] <= 150) {
      format_new["colorPm25"] = "#EA3223";
      if (stap_status <= 4) {
        format_new["color"] = "#EA3223";
        format_new["text"] = "Sensitive";
        stap_status = 4;
      }
    } else if (format_new["pm25"] >= 26 && format_new["pm25"] <= 65) {
      format_new["colorPm25"] = "#F47C0D";
      if (stap_status <= 3) {
        format_new["color"] = "#F47C0D";
        format_new["text"] = "Moderate";
        stap_status = 3;
      }
    } else if (format_new["pm25"] >= 16 && format_new["pm25"] <= 25) {
      format_new["colorPm25"] = "#F6BF42";
      if (stap_status <= 2) {
        format_new["color"] = "#F6BF42";
        format_new["text"] = "Good";
        stap_status = 2;
      }
    } else if (format_new["pm25"] < 15) {
      format_new["colorPm25"] = "#67AC5B";
      if (stap_status <= 1) {
        format_new["color"] = "#67AC5B";
        format_new["text"] = "Excellent";
        stap_status = 1;
      }
    }
    // end PM25

    set_data.push(format_new);
  }

  // console.log(set_data);

  return set_data;
};

export default setformatiaq;
