import { Switch, Space, Image } from "antd";
import ControllerPicture from "../picture/controller";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import apipostLight from "../../api/controller/postLight";

import { getcontroller, setshowCtlStored } from "../../stored/mydata";

export default function Lamp(props) {
  const dispatch = useDispatch();

  const dataController = useSelector(getcontroller);
  const [dataDevice, setDataDevice] = useState(props.data);

  useEffect(() => {
    if (
      dataDevice["device_id"] === dataController["device_id"] &&
      dataDevice["mac"] === dataController["mac"]
    ) {
      setDataDevice({
        ...dataDevice,
        device_status: dataController["status"],
      });

      // dataDevice["device_status"] = dataController["status"];
    }
  }, [dataController]);

  useEffect(() => {}, [dataDevice]);

  const apiControll = (value) => {
    // dispatch(setshowCtlStored(true));

    const data_api = JSON.stringify(value);

    apipostLight(data_api, (err, res) => {
      if (res["message"] === "Success") {
        setDataDevice({
          ...dataDevice,
          device_status: value.status,
        });
        // console.log("controllerSwit");
        //   var count_time = 3;
        //   let timerId = setInterval(() => {
        //     count_time--;

        //     if (count_time === 0) {
        //       dispatch(setshowCtlStored(false));
        //       clearInterval(timerId);
        //     }
        //   }, 1000);
      }
    });
  };

  const controllerSwitch = (e) => {
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      status: "on",
    };

    if (e) {
      data["status"] = "on";
    } else {
      data["status"] = "off";
    }
    apiControll(data);
  };

  return (
    <div>
      <div
        style={
          dataDevice["device_status"] === "on" ? styles.cardAction : styles.card
        }
      >
        <div style={{ textAlign: "end" }}>
          <Space direction="vertical">
            <Switch
              onChange={controllerSwitch}
              checked={dataDevice["device_status"] === "on" ? true : false}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              // defaultChecked
            />
          </Space>
        </div>
        <div
          style={{ textAlign: "center", marginTop: "15px" }}
          onClick={() => {
            if (dataDevice["device_status"] === "on") {
              controllerSwitch(false);
            } else {
              controllerSwitch(true);
            }
          }}
        >
          <Image
            preview={false}
            width={50}
            src={
              "https://oneapp.obotrons.com/public/img/devices/main_light.svg"
            }
          />
        </div>
        <div
          style={
            dataDevice["device_status"] === "on"
              ? styles.fontHead
              : styles.fontHeadoff
          }
        >
          {dataDevice["device_name"]}
        </div>
        {/* <div style={styles.textNumber}>{props.data["number"]}%</div> */}
        {/* <div>
          <Slider
            defaultValue={parseInt(props.data["number"])}
            max={100}
            tooltip={{
              formatter: null,
            }}
          />
        </div> */}
      </div>
    </div>
  );
}

const styles = {
  card: {
    height: "130px",
    padding: "16px",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    // border: "1px solid #B5EAEF",
    background: "#FBFBFB",
  },
  cardAction: {
    height: "130px",
    padding: "16px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#EBF4FA",
  },
  fontHead: {
    color: "#3EA8B8",
    fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
    textAlign: "center",
  },
  fontHeadoff: {
    color: "#E3E3E3",
    fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
    textAlign: "center",
  },
  textNumber: {
    color: "#56585D",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "21px",
    letterSpacing: "0.1px",
  },
};
