import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Switch, Space, Slider, Spin } from "antd";
import ControllerPicture from "../picture/controller";
import apipostAc from "../../api/controller/postAc";

import { getcontroller, setshowCtlStored } from "../../stored/mydata";

export default function AirCassette(props) {
  const dispatch = useDispatch();

  const dataController = useSelector(getcontroller);
  const [dataDevice, setDataDevice] = useState(props.data);

  useEffect(() => {}, [dataDevice]);

  useEffect(() => {
    if (dataDevice["mac"] === dataController["mac"]) {
      setDataDevice({
        ...dataDevice,
        device_temp:
          dataController["temp"] === "error" ? "" : dataController["temp"],
        device_mode: dataController["mode"],
        device_fanspeed: dataController["speed"],
        device_status: dataController["status"],
      });
      // dataDevice["device_temp"] = dataController["temp"];
      // dataDevice["device_mode"] = dataController["mode"];
      // dataDevice["device_fanspeed"] = dataController["speed"];
      // dataDevice["device_status"] = dataController["status"];
    }
  }, [dataController]);

  const fanspeed = (value) => {
    if (value === "low") {
      return 0;
    } else if (value === "medium") {
      return 25;
    } else if (value === "hight") {
      return 50;
    } else if (value === "quiet") {
      return 75;
    } else if (value === "auto") {
      return 100;
    } else {
      return 0;
    }
  };

  const modes = [
    {
      key: 1,
      name: "Fan",
      status: "off",
    },
    {
      key: 2,
      name: "Cool",
      status: "off",
    },
    {
      key: 3,
      name: "Auto",
      status: "off",
    },
  ];
  const marks = {
    0: (
      <>
        <div style={styles.textMode}>Low</div>
      </>
    ),
    25: (
      <>
        <div style={styles.textMode}>Mid</div>
      </>
    ),
    50: (
      <>
        <div style={styles.textMode}>High</div>
      </>
    ),
    75: (
      <>
        <div style={styles.textMode}>Quiet</div>
      </>
    ),
    100: (
      <>
        <div style={styles.textMode}>Auto</div>
      </>
    ),
  };

  const apiControll = (value) => {
    // dispatch(setshowCtlStored(true));

    // device_brightness: null;
    // device_endpoint_status: null;
    // device_fanspeed: "quiet";
    // device_gang: null;
    // device_group: null;
    // device_id: "ac";
    // device_mode: "auto";
    // device_model: "cassette";
    // device_name: "Air Condition Living Room";
    // device_status: "on";
    // device_sweep: "on";
    // device_temp: "22";
    // device_type: "ac";
    // house_number: "181/651";

    const data_api = JSON.stringify(value);

    apipostAc(data_api, (err, res) => {
      if (res["message"] === "Success") {
        if (value.fn === "status") {
          setDataDevice({ ...dataDevice, device_status: value.val });
        } else if (value.fn === "temp") {
          setDataDevice({ ...dataDevice, device_temp: value.val });
        } else if (value.fn === "mode") {
          setDataDevice({ ...dataDevice, device_mode: value.val });
        } else if (value.fn === "speed") {
          setDataDevice({ ...dataDevice, device_fanspeed: value.val });
        }
        // var count_time = 3;
        // let timerId = setInterval(() => {
        //   count_time--;
        //   if (count_time === 0) {
        //     dispatch(setshowCtlStored(false));
        //     clearInterval(timerId);
        //   }
        // }, 1000);
      }
    });
  };

  const controllerSwitch = (e) => {
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      fn: "status",
      val: e,
    };

    if (e) {
      data["val"] = "on";
    } else {
      data["val"] = "off";
    }

    apiControll(data);
  };

  const controllerTemp = (value) => {
    const number = parseInt(dataDevice["device_temp"]) + value;

    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      fn: "temp",
      val: number + "",
    };

    if (number >= 15 && number <= 30) {
      apiControll(data);
    }
  };

  const controllerMode = (value) => {
    // console.log(value);
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      fn: "mode",
      val: value.toLowerCase(),
    };
    apiControll(data);
  };

  const controllerSpeed = (value) => {
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      fn: "speed",
      val: "",
    };

    if (value === 0) {
      data["val"] = "low";
    } else if (value === 25) {
      data["val"] = "medium";
    } else if (value === 50) {
      data["val"] = "hight";
    } else if (value === 75) {
      data["val"] = "quiet";
    } else if (value === 100) {
      data["val"] = "auto";
    } else {
      data["val"] = "low";
    }

    apiControll(data);
  };

  return (
    <div>
      <div
        style={
          dataDevice["device_status"] === "on"
            ? styles.cardAir
            : styles.cardAiroff
        }
      >
        <Row gutter={[12, 12]}>
          <Col
            span={5}
            style={{ width: "250px" }}
            onClick={() => {
              if (dataDevice["device_status"] === "on") {
                controllerSwitch(false);
              } else {
                controllerSwitch(true);
              }
            }}
          >
            {ControllerPicture.air()}
          </Col>
          <Col span={19}>
            <Row>
              <Col
                span={15}
                onClick={() => {
                  if (dataDevice["device_status"] === "on") {
                    controllerSwitch(false);
                  } else {
                    controllerSwitch(true);
                  }
                }}
              >
                <div
                  style={
                    dataDevice["device_status"] === "on"
                      ? styles.fontHead
                      : styles.fontHeadoff
                  }
                >
                  Air Conditioner
                </div>
              </Col>
              <Col span={9} style={{ textAlign: "end", paddingTop: "3px" }}>
                <Space direction="vertical" style={{ marginLeft: "23%" }}>
                  <Switch
                    // size="small"
                    // checked={checked} onChange={setChecked}
                    onChange={controllerSwitch}
                    checked={
                      dataDevice["device_status"] === "on" ? true : false
                    }
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                  />
                </Space>
              </Col>
            </Row>
            <div style={{ marginTop: "2%" }}>
              <Row>
                <Col span={8} style={{ width: "250px", textAlign: "center" }}>
                  {dataDevice["device_mode"] !== "fan" &&
                  dataDevice["device_status"] === "on" ? (
                    <div
                      onClick={() => {
                        controllerTemp(-1);
                      }}
                    >
                      {ControllerPicture.minus()}
                    </div>
                  ) : (
                    <div>{ControllerPicture.minusD()}</div>
                  )}
                </Col>
                <Col span={8} style={styles.fontNumber}>
                  {dataDevice["device_temp"]}°
                </Col>
                <Col span={8} style={{ width: "250px", textAlign: "center" }}>
                  {dataDevice["device_mode"] !== "fan" &&
                  dataDevice["device_status"] === "on" ? (
                    <div
                      onClick={() => {
                        controllerTemp(1);
                      }}
                    >
                      {ControllerPicture.plus()}
                    </div>
                  ) : (
                    <div>{ControllerPicture.plusD()}</div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div>
          {dataDevice["device_status"] === "on" ? (
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
              {modes.map((e, i) => {
                return (
                  <Col span={8} key={i + "_mode"}>
                    <div
                      style={
                        dataDevice["device_mode"] === e.name.toLowerCase()
                          ? styles.cardModeAction
                          : styles.cardMode
                      }
                      onClick={() => {
                        controllerMode(e.name);
                      }}
                    >
                      <div>
                        {e.name === "Fan" ? (
                          <>{ControllerPicture.dry()}</>
                        ) : null}
                        {e.name === "Cool" ? (
                          <>{ControllerPicture.cool()}</>
                        ) : null}
                        {e.name === "Auto" ? (
                          <>{ControllerPicture.auto()}</>
                        ) : null}
                      </div>
                      <div style={styles.fontInCard}>{e.name}</div>
                      <div style={{ marginTop: "10px" }}>
                        <Switch
                          checked={
                            dataDevice["device_mode"] === e.name.toLowerCase()
                              ? true
                              : false
                          }
                          checkedChildren="ON"
                          unCheckedChildren="OFF"
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
              {modes.map((e, i) => {
                return (
                  <Col span={8} key={i + "_mode"}>
                    <div
                      style={
                        dataDevice["device_mode"] === e.name.toLowerCase()
                          ? styles.cardModeActionoff
                          : styles.cardModeoff
                      }
                    >
                      <div>
                        {e.name === "Fan" ? (
                          <>{ControllerPicture.dry()}</>
                        ) : null}
                        {e.name === "Cool" ? (
                          <>{ControllerPicture.cool()}</>
                        ) : null}
                        {e.name === "Auto" ? (
                          <>{ControllerPicture.auto()}</>
                        ) : null}
                      </div>
                      <div style={styles.fontInCardoff}>{e.name}</div>
                      <div style={{ marginTop: "10px" }}>
                        <Switch
                          disabled
                          checked={false}
                          checkedChildren="ON"
                          unCheckedChildren="OFF"
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
        <div
          style={
            dataDevice["device_status"] === "on"
              ? styles.cardInerv
              : styles.cardInervoff
          }
        >
          {dataDevice["device_status"] === "on" ? (
            <Row>
              <Col span={7}>
                <Row>
                  <Col span={12} style={{ width: "200px" }}>
                    {ControllerPicture.wind()}
                  </Col>
                  <Col span={12}>
                    <div style={styles.fontInpage}>Fan</div>
                  </Col>
                </Row>
              </Col>
              <Col span={17}>
                <Slider
                  marks={marks}
                  value={fanspeed(dataDevice["device_fanspeed"])}
                  step={25}
                  onChange={controllerSpeed}
                  tooltip={{
                    formatter: null,
                  }}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={7}>
                <Row>
                  <Col span={12} style={{ width: "200px" }}>
                    {ControllerPicture.wind()}
                  </Col>
                  <Col span={12}>
                    <div style={styles.fontInpageoff}>Fan</div>
                  </Col>
                </Row>
              </Col>
              <Col span={17}>
                <Slider
                  disabled
                  marks={marks}
                  value={fanspeed(dataDevice["device_fanspeed"])}
                  step={25}
                  tooltip={{
                    formatter: null,
                  }}
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "108px",
  },
  cardAir: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
  },
  cardInerv: {
    // width: "90%",
    // width: "100%",
    // height: "62px",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#FBFBFB",
    padding: "15px 15px 0 15px",
  },
  fontInpage: {
    color: "#3EA8B8",
    // textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  fontHead: {
    fontFamily: "Gotham",
    color: "#3EA8B8",
    textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
    // marginLeft: "25px",
  },
  fontInCard: {
    color: "#3EA8B8",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
    // marginLeft: "10%",
  },
  fontNumber: {
    color: "#56585D",
    fontFamily: "Gotham",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "32px",
    letterSpacing: "0.15px",
    textAlign: "center",
    marginTop: "10px",
  },
  textMode: {
    color: "#56585D",
    marginTop: "10px",
    textAlign: "center",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px" /* 175% */,
  },
  cardMode: {
    height: "105px",
    // width: "93px",
    textAlign: "center",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#FBFBFB",
    paddingTop: "15%",
  },
  cardModeAction: {
    height: "105px",
    // width: "93px",
    textAlign: "center",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "rgba(235, 244, 250, 1)",
    paddingTop: "15%",
  },

  cardAiroff: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
  },
  fontHeadoff: {
    fontFamily: "Gotham",
    color: "#E3E3E3",
    textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  cardModeoff: {
    height: "105px",
    // width: "93px",
    textAlign: "center",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    background: "#FBFBFB",
    paddingTop: "15%",
  },
  cardModeActionoff: {
    height: "105px",
    // width: "93px",
    textAlign: "center",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    background: "#E3E3E3",
    paddingTop: "15%",
  },
  fontInCardoff: {
    color: "#56585D",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
    // marginLeft: "10%",
  },
  fontInpageoff: {
    color: "#E3E3E3",
    // textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  cardInervoff: {
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    background: "rgba(251, 251, 251, 0.1)",
    padding: "15px 15px 0 15px",
  },
};
