import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Modal, Button, Switch, Space, Radio, Slider } from "antd";
import ControllerPicture from "../../components/picture/controller";
import apipostErv from "../../api/controller/postErv";

import { getcontroller, setshowCtlStored } from "../../stored/mydata";

export default function Erv(props) {
  // const dispatch = useDispatch();

  const dataController = useSelector(getcontroller);
  const [dataDevice, setDataDevice] = useState(props.data);

  const [valueMode, setValueMode] = useState(props.data["device_mode"]);

  useEffect(() => {
    // console.log(props);

    // setDataDevice(props.data);
    // setValueMode(props.data["device_mode"]);

  }, [dataDevice]);

  useEffect(() => {
    // console.log(dataController);
    if (dataDevice["mac"] === dataController["mac"]) {
      // setDataDevice({
      //   ...dataDevice,
      //   device_mode: dataController["mode"],
      //   device_fanspeed: dataController["speed"],
      //   device_status: dataController["status"],
      // });
      dataDevice["device_mode"] = dataController["mode"];
      dataDevice["device_fanspeed"] = dataController["speed"];
      dataDevice["device_status"] = dataController["status"];
      setDataDevice({ ...dataDevice, device_mode: dataController["mode"] });
    }
  }, [dataController]);

  useEffect(() => {}, [dataDevice]);

  const ervspeed = (value) => {
    if (value === "low") {
      return 0;
    } else if (value === "medium") {
      return 50;
    } else if (value === "high") {
      return 100;
    } else {
      return 0;
    }
  };

  const modes = [
    {
      key: 1,
      value: "supply",
      name: "Supply",
    },
    {
      key: 2,
      value: "vent",
      name: "Vent",
    },
    {
      key: 3,
      value: "heat",
      name: "Heat",
    },
  ];

  const marks = {
    0: (
      <>
        <div style={styles.textModeFan}>Low</div>
      </>
    ),
    50: (
      <>
        <div style={styles.textModeFan}>Mid</div>
      </>
    ),
    100: (
      <>
        <div style={styles.textModeFan}>High</div>
      </>
    ),
  };

  const apiControll = (value) => {
    // dispatch(setshowCtlStored(true));

    // device_brightness: null;
    // device_endpoint_status: null;
    // device_fanspeed: "medium";
    // device_gang: null;
    // device_group: null;
    // device_id: "erv";
    // device_mode: "supply";
    // device_model: "vent";
    // device_name: "ERV Living Room";
    // device_status: "off";
    // device_sweep: null;
    // device_temp: null;
    // device_type: "erv";

    const data_api = JSON.stringify(value);

    apipostErv(data_api, (err, res) => {
      if (res["message"] === "Success") {
        if (value.fn === "status") {
          setDataDevice({ ...dataDevice, device_status: value.val });
        } else if (value.fn === "mode") {
          setValueMode(value.val);
          setDataDevice({ ...dataDevice, device_mode: value.val });
        } else if (value.fn === "speed") {
          setDataDevice({ ...dataDevice, device_fanspeed: value.val });
        }

        // setDataDevice({
        //   ...dataDevice,
        //   device_status: value.status,
        // });

        // var count_time = 3;
        // let timerId = setInterval(() => {
        //   count_time--;

        //   if (count_time === 0) {
        //     dispatch(setshowCtlStored(false));
        //     clearInterval(timerId);
        //   }
        // }, 1000);
      }
    });
  };

  const controllerSwitch = (e) => {
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_mode"],
      device_type: dataDevice["device_type"],
      fn: "status",
      val: e,
    };

    if (e) {
      data["val"] = "on";
    } else {
      data["val"] = "off";
    }

    apiControll(data);
  };

  const controllerMode = (value) => {
    var data_value = value.target.value;
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_mode"],
      device_type: dataDevice["device_type"],
      fn: "mode",
      val: data_value.toLowerCase(),
    };
    apiControll(data);
  };

  const controllerSpeed = (value) => {
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_mode"],
      device_type: dataDevice["device_type"],
      fn: "speed",
      val: "",
    };

    if (value === 0) {
      data["val"] = "low";
    } else if (value === 50) {
      data["val"] = "medium";
    } else if (value === 100) {
      data["val"] = "high";
    } else {
      data["val"] = "low";
    }

    apiControll(data);
  };

  return (
    <div>
      <div
        style={
          dataDevice["device_status"] === "on" ? styles.card : styles.cardoff
        }
      >
        <Row>
          <Col span={20}>
            <Row
              onClick={() => {
                if (dataDevice["device_status"] === "on") {
                  controllerSwitch(false);
                } else {
                  controllerSwitch(true);
                }
              }}
            >
              <Col span={3} style={{ width: "200px" }}>
                {dataDevice["device_status"] === "on"
                  ? ControllerPicture.erv()
                  : ControllerPicture.ervOff()}
              </Col>
              <Col span={21}>
                <div
                  style={
                    dataDevice["device_status"] === "on"
                      ? styles.fontInpage
                      : styles.fontInpageoff
                  }
                >
                  Energy Recovery Ventilation
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={4} style={{ textAlign: "end", paddingTop: "3px" }}>
            <Space direction="vertical">
              <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                onChange={controllerSwitch}
                checked={dataDevice["device_status"] === "on" ? true : false}
              />
            </Space>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={4} style={styles.textMode}>
            Mode:
          </Col>
          <Col span={20}>
            {dataDevice["device_status"] === "on" ? (
              <Radio.Group onChange={controllerMode} value={valueMode}>
                <Row>
                  {modes.map((mode) => (
                    <Col span={8} key={mode.value}>
                      <Radio value={mode.value}>
                        <div
                          style={
                            valueMode === mode.value
                              ? styles.fontNumber_choose
                              : styles.fontNumber
                          }
                        >
                          {mode.name === "Heat" && (
                            <>{ControllerPicture.heat()}</>
                          )}
                          {mode.name === "Vent" && (
                            <>{ControllerPicture.vent()}</>
                          )}
                          {mode.name === "Supply" && (
                            <>{ControllerPicture.supply()}</>
                          )}
                        </div>
                      </Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            ) : (
              <Radio.Group disabled value={valueMode}>
                <Row>
                  {modes.map((e) => {
                    return (
                      <Col span={8} key={e.value}>
                        <div>
                          <Radio value={e.value}>
                            <div>
                              {e.name === "Heat" ? (
                                <>{ControllerPicture.heat()}</>
                              ) : null}
                              {e.name === "Vent" ? (
                                <>{ControllerPicture.vent()}</>
                              ) : null}
                              {e.name === "Supply" ? (
                                <>{ControllerPicture.supply()}</>
                              ) : null}
                            </div>
                          </Radio>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Radio.Group>
            )}
          </Col>
        </Row>
        <div
          style={
            dataDevice["device_status"] === "on"
              ? styles.cardInerv
              : styles.cardInervoff
          }
        >
          <Row gutter={[8, 8]}>
            <Col span={7}>
              <div>
                <Row>
                  <Col span={12} style={{ width: "250px" }}>
                    {ControllerPicture.wind()}
                  </Col>
                  <Col span={12}>
                    <div
                      style={
                        dataDevice["device_status"] === "on"
                          ? styles.fontInpage
                          : styles.fontInpageoff
                      }
                    >
                      Fan
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            {dataDevice["device_status"] === "on" ? (
              <Col span={17}>
                <div>
                  <Slider
                    marks={marks}
                    defaultValue={50}
                    step={50}
                    value={ervspeed(dataDevice["device_fanspeed"])}
                    onChange={controllerSpeed}
                    tooltip={{
                      formatter: null,
                    }}
                  />
                </div>
              </Col>
            ) : (
              <Col span={17}>
                <div>
                  <Slider
                    disabled
                    marks={marks}
                    value={ervspeed(dataDevice["device_fanspeed"])}
                    step={50}
                    tooltip={{
                      formatter: null,
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "108px",
  },
  card: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    // alignItems: "flex-start",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
  },
  cardInerv: {
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "rgba(251, 251, 251, 0.1)",
    padding: "15px 15px 0 15px",
  },
  fontInpage: {
    color: "#3EA8B8",
    // textAlign: "center",
    paddingTop: "5px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  fontNumber_choose: {
    color: "#56585D",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  fontNumber: {
    color: "#919499",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  textMode: {
    color: "#56585D",
    // textAlign: "center",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },
  textModeFan: {
    color: "#56585D",
    // textAlign: "center",
    marginTop: "10px",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },

  cardoff: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
  },
  fontInpageoff: {
    color: "#E3E3E3",
    paddingTop: "5px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  cardInervoff: {
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    background: "rgba(251, 251, 251, 0.1)",
    padding: "15px 15px 0 15px",
  },
};
