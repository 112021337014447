import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Modal, Button, Switch, Space, DatePicker, Spin } from "antd";
import ControllerPicture from "../../components/picture/controller";
import apipostPir from "../../api/controller/postPir";
import apigetTimepir from "../../api/controller/getTimepir";
import apipostTimepir from "../../api/controller/postTimepir";
import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

import { getcontroller, setshowCtlStored } from "../../stored/mydata";

export default function MotionSenser(props) {
  const dispatch = useDispatch();

  const dataController = useSelector(getcontroller);
  const [dataDevice, setDataDevice] = useState(props.data);
  const [modelDelete, setModalDelete] = useState(false);
  const [modelTime, setModalTime] = useState(false);
  const [timePir, setTimepir] = useState({
    time: "00:00",
  });

  const [defaultTime, setDefaulTime] = useState("2022-04-01T17:00:00");

  const [opentModel, setOpenmodel] = useState(false);

  // useEffect(() => {
  //   setDataDevice(props.data);
  // }, [dataDevice]);

  useEffect(() => {}, [dataDevice]);

  useEffect(() => {
    // console.log(dataController);
    if (
      dataDevice["mac"] === dataController["mac"] &&
      dataDevice["device_id"] === dataController["device_id"]
    ) {
      setDataDevice({
        ...dataDevice,
        device_status: dataController["status"],
      });
      // dataDevice["device_status"] = dataController["status"];
    }
  }, [dataController]);

  const apiControll = (value) => {
    // dispatch(setshowCtlStored(true));

    const data_api = JSON.stringify(value);

    apipostPir(data_api, (err, res) => {
      if (res["message"] === "Success") {
        setDataDevice({
          ...dataDevice,
          device_status: value.status,
        });
        // var count_time = 3;
        // let timerId = setInterval(() => {
        //   count_time--;

        //   if (count_time === 0) {
        //     dispatch(setshowCtlStored(false));
        //     clearInterval(timerId);
        //   }
        // }, 1000);
      }
    });
  };

  const apiGetTime = () => {
    dispatch(setshowCtlStored(true));
    // startModal();

    const data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
    };

    apigetTimepir(data, (err, res) => {
      if (res["message"] === "Success") {
        var secs = res.time;
        var formatted = "";

        var time_mm = 0;
        var time_ss = 0;

        // console.log(80/60);
        // console.log(80%60);

        // const secs = res.time;
        if (secs > 60) {
          time_mm = parseInt(secs / 60);
          time_ss = parseInt(secs % 60);

          formatted = dayjs("2022-04-01T17:00:00")
            .add(time_mm, "minute")
            .add(time_ss, "second");
        } else {
          formatted = dayjs("2022-04-01T17:00:00").add(secs, "second");
        }

        setDefaulTime(formatted);
        // setTimepir({ ...timePir, time: formatted });

        dispatch(setshowCtlStored(false));
        startModal();
        // stopModal();
      }
    });
  };

  const apiPostTime = () => {
    setOpenmodel(true);

    var sum = 0;

    var time_minutes = parseInt(timePir.time["$m"]);
    var time_seconds = parseInt(timePir.time["$s"]);
    sum = sum + time_minutes * 60;
    sum = sum + time_seconds;

    const data = JSON.stringify({
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      time: sum.toString(),
    });

    apipostTimepir(data, (err, res) => {
      if (res["message"] === "Success") {
        setOpenmodel(false);
        stopModal();
      }
    });
  };

  const controllerSwitch = (e) => {
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      status: "",
    };

    if (e) {
      data["status"] = "on";
    } else {
      data["status"] = "off";
    }

    apiControll(data);
  };

  const stopModal = () => {
    setModalTime(false);
  };
  const startModal = () => {
    setModalTime(true);
  };

  return (
    <div>
      <div
        style={
          dataDevice["device_status"] === "on" ? styles.card : styles.cardoff
        }
      >
        <Row>
          <Col
            span={20}
            onClick={() => {
              if (dataDevice["device_status"] === "on") {
                apiGetTime();
              }
            }}
          >
            <Row>
              <Col span={3} style={{ width: "200px" }}>
                {dataDevice["device_status"] === "on"
                  ? ControllerPicture.motionseneor()
                  : ControllerPicture.motionseneoroff()}
              </Col>
              <Col span={21}>
                <div
                  style={
                    dataDevice["device_status"] === "on"
                      ? styles.fontInpage
                      : styles.fontInpageoff
                  }
                >
                  {dataDevice["device_name"]}
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={4} style={{ textAlign: "end", paddingTop: "3px" }}>
            <Space direction="vertical">
              <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                onChange={controllerSwitch}
                checked={dataDevice["device_status"] === "on" ? true : false}
              />
            </Space>
          </Col>
        </Row>

        <Modal
          centered={true}
          open={modelTime}
          onCancel={stopModal}
          footer={null}
          closable={false}
        >
          <Spin spinning={opentModel} size="large" style={{ display: "flow" }}>
            <div style={{ display: "grid" }}>
              <div style={{ display: "grid" }}>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                  <Col style={styles.textHeadModal}>Setting Timer</Col>
                  <Col style={{ marginLeft: "2%", marginTop: "2%" }}></Col>
                </Row>
              </div>
              <div style={{ marginTop: "15px" }}>
                <div style={styles.textRead}>
                  <span style={{ paddingLeft: "4px" }}>
                    select timer duration for Motion Sensor
                  </span>
                </div>
              </div>
              <Row
                style={{
                  margin: "10px 20px 5px 20px",
                  textAlign: "center",
                }}
              >
                <Col span={24}>
                  <span style={styles.textRead}>min : sec</span>
                </Col>
              </Row>
              <Row
                style={{
                  margin: "5px 20px 10px 20px",
                  textAlign: "center",
                }}
              >
                <Col span={7}></Col>
                <Col span={10}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ paddingRight: "0px !important" }}
                  >
                    <MobileTimePicker
                      openTo="seconds"
                      defaultValue={defaultTime}
                      views={["minutes", "seconds"]}
                      format="mm:ss"
                      onChange={(value) => {
                        setTimepir({ ...timePir, time: value });
                      }}
                    />
                  </LocalizationProvider>
                  {/* <DatePicker
                        defaultValue={dayjs(timePir.time, "mm:ss")}
                        picker={"time"}
                        format="mm : ss"
                        onChange={(value) => {
                          setTimepir({ ...timePir, time: value });
                        }}
                      /> */}
                </Col>
                <Col span={7}></Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col span={12} style={{ display: "inline-grid" }}>
                  <Button
                    onClick={() => {
                      stopModal();
                    }}
                    style={{
                      backgroundColor: "#ffffff",
                      borderColor: "#ffffff",
                      color: "#919499",
                      fontSize: "15px",
                      fontWeight: "400",
                      height: "45px",
                      textTransform: "uppercase",
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12} style={{ display: "inline-grid" }}>
                  <Button
                    onClick={() => {
                      // deleteScene(scene.id);
                      apiPostTime();
                    }}
                    style={{
                      backgroundColor: "#ffffff",
                      borderColor: "#ffffff",
                      color: "#3EA8B8",
                      fontSize: "15px",
                      fontWeight: "400",
                      height: "45px",
                      textTransform: "uppercase",
                    }}
                  >
                    Ok
                  </Button>
                </Col>
              </Row>
            </div>
          </Spin>
        </Modal>
      </div>
    </div>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "108px",
  },
  card: {
    display: "flex",
    padding: "16px",
    // flexDirection: "column",
    // alignItems: "flex-start",
    // gap: "12px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
  },
  fontInpage: {
    color: "#3EA8B8",
    // textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  fontNumber_choose: {
    color: "#56585D",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  fontNumber: {
    color: "#919499",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  textMode: {
    color: "#56585D",
    // textAlign: "center",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },
  textModeFan: {
    color: "#56585D",
    // textAlign: "center",
    marginTop: "10px",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },
  textHeadModal: {
    color: "#3EA8B8",
    textAlign: "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    fontFamily: "Gotham",
  },

  cardoff: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    // alignItems: "flex-start",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
  },
  fontInpageoff: {
    color: "#E3E3E3",
    // textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  textRead: {
    color: "#56585D",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
  },
};
