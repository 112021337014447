import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Switch, Space, Slider, Spin } from "antd";
import ControllerPicture from "../picture/controller";
import apipostAc from "../../api/controller/postAc";

import { getcontroller, setshowCtlStored } from "../../stored/mydata";

export default function AirCassette2(props) {
  const dispatch = useDispatch();

  const dataController = useSelector(getcontroller);
  const [dataDevice, setDataDevice] = useState(props.data);

  useEffect(() => {}, [dataDevice]);

  useEffect(() => {
    if (dataDevice["mac"] === dataController["mac"]) {
      setDataDevice({
        ...dataDevice,
        device_temp:
          dataController["temp"] === "error" ? "" : dataController["temp"],
        device_mode: dataController["mode"],
        device_fanspeed: dataController["speed"],
        device_status: dataController["status"],
      });
      // dataDevice["device_temp"] = dataController["temp"];
      // dataDevice["device_mode"] = dataController["mode"];
      // dataDevice["device_fanspeed"] = dataController["speed"];
      // dataDevice["device_status"] = dataController["status"];
    }
  }, [dataController]);

  const fanspeed = (value) => {
    if (value === "low") {
      return 0;
    } else if (value === "medium") {
      return 33;
    } else if (value === "hight") {
      return 66;
    } else if (value === "auto") {
      return 100;
    } else {
      return 0;
    }
  };

  const modes = [
    {
      key: 1,
      name: "Fan",
      status: "off",
    },
    {
      key: 2,
      name: "Cool",
      status: "off",
    },
    {
      key: 3,
      name: "Dry",
      status: "off",
    },
  ];
  const marks = {
    0: (
      <>
        <div style={styles.textMode}>Low</div>
      </>
    ),
    33: (
      <>
        <div style={styles.textMode}>Mid</div>
      </>
    ),
    66: (
      <>
        <div style={styles.textMode}>High</div>
      </>
    ),
    100: (
      <>
        <div style={styles.textMode}>auto</div>
      </>
    ),
  };

  const apiControll = (value) => {
    // dispatch(setshowCtlStored(true));

    const data_api = JSON.stringify(value);

    apipostAc(data_api, (err, res) => {
      if (res["message"] === "Success") {
        // console.log("controllerSwit");

        if (value.fn === "status") {
          setDataDevice({ ...dataDevice, device_status: value.val });
        } else if (value.fn === "temp") {
          setDataDevice({ ...dataDevice, device_temp: value.val });
        } else if (value.fn === "mode") {
          setDataDevice({ ...dataDevice, device_mode: value.val });
        } else if (value.fn === "speed") {
          setDataDevice({ ...dataDevice, device_fanspeed: value.val });
        }

        // var count_time = 3;
        // let timerId = setInterval(() => {
        //   count_time--;

        //   if (count_time === 0) {
        //     dispatch(setshowCtlStored(false));
        //     clearInterval(timerId);
        //   }
        // }, 1000);
      }
    });
  };

  const controllerSwitch = (e) => {
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      fn: "status",
      val: e,
    };

    if (e) {
      data["val"] = "on";
    } else {
      data["val"] = "off";
    }

    apiControll(data);
  };

  const controllerTemp = (value) => {
    const number = parseInt(dataDevice["device_temp"]) + value;

    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      fn: "temp",
      val: number + "",
    };

    if (number >= 18 && number <= 32) {
      apiControll(data);
    }
  };

  const controllerMode = (value) => {
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      fn: "mode",
      val: value.toLowerCase(),
    };
    apiControll(data);
  };

  const controllerSpeed = (value) => {
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      fn: "speed",
      val: "",
    };

    if (value === 0) {
      data["val"] = "low";
    } else if (value === 33) {
      data["val"] = "medium";
    } else if (value === 66) {
      data["val"] = "hight";
    } else if (value === 100) {
      data["val"] = "auto";
    } else {
      data["val"] = "low";
    }

    apiControll(data);
  };

  return (
    <div>
      <div style={styles.cardErv}>
        <Row gutter={[12, 12]}>
          <Col
            span={5}
            style={{ width: "250px" }}
            onClick={() => {
              if (dataDevice["device_status"] === "on") {
                controllerSwitch(false);
              } else {
                controllerSwitch(true);
              }
            }}
          >
            {ControllerPicture.air()}
          </Col>
          <Col span={19}>
            <Row>
              <Col
                span={15}
                onClick={() => {
                  if (dataDevice["device_status"] === "on") {
                    controllerSwitch(false);
                  } else {
                    controllerSwitch(true);
                  }
                }}
              >
                <div style={styles.fontHead}>Air Conditioner</div>
              </Col>
              <Col span={9} style={{ textAlign: "end", paddingTop: "3px" }}>
                <Space direction="vertical" style={{ marginLeft: "23%" }}>
                  <Switch
                    // size="small"
                    // checked={checked} onChange={setChecked}
                    onChange={controllerSwitch}
                    checked={
                      dataDevice["device_status"] === "on" ? true : false
                    }
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                  />
                </Space>
              </Col>
            </Row>
            <div style={{ marginTop: "2%" }}>
              <Row>
                <Col span={8} style={{ width: "250px", textAlign: "center" }}>
                  {dataDevice["device_mode"] !== "fan" ? (
                    <div
                      onClick={() => {
                        controllerTemp(-1);
                      }}
                    >
                      {ControllerPicture.minus()}
                    </div>
                  ) : (
                    <div>{ControllerPicture.minusD()}</div>
                  )}
                </Col>
                <Col span={8} style={styles.fontNumber}>
                  {dataDevice["device_temp"]}°
                </Col>
                <Col span={8} style={{ width: "250px", textAlign: "center" }}>
                  {dataDevice["device_mode"] !== "fan" ? (
                    <div
                      onClick={() => {
                        controllerTemp(1);
                      }}
                    >
                      {ControllerPicture.plus()}
                    </div>
                  ) : (
                    <div>{ControllerPicture.plusD()}</div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div>
          <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
            {modes.map((e, i) => {
              return (
                <Col span={8} key={i + "_mode"}>
                  <div
                    style={
                      dataDevice["device_mode"] === e.name.toLowerCase()
                        ? styles.cardModeAction
                        : styles.cardMode
                    }
                    onClick={() => {
                      controllerMode(e.name);
                    }}
                  >
                    <div>
                      {e.name === "Dry" ? <>{ControllerPicture.dry()}</> : null}
                      {e.name === "Cool" ? (
                        <>{ControllerPicture.cool()}</>
                      ) : null}
                      {e.name === "Fan" ? <>{ControllerPicture.eco()}</> : null}
                    </div>
                    <div style={styles.fontInCard}>{e.name}</div>
                    <div style={{ marginTop: "10px" }}>
                      <Switch
                        checked={
                          dataDevice["device_mode"] === e.name.toLowerCase()
                            ? true
                            : false
                        }
                        // checked={checked} onChange={setChecked}
                        // onChange={controllerMode(e.name)}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                      />
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        <div style={styles.cardInerv}>
          <Row>
            <Col span={7}>
              <Row>
                <Col span={12} style={{ width: "200px" }}>
                  {ControllerPicture.wind()}
                </Col>
                <Col span={12}>
                  <div style={styles.fontInpage}>Fan</div>
                </Col>
              </Row>
            </Col>
            <Col span={17}>
              <Slider
                marks={marks}
                value={fanspeed(dataDevice["device_fanspeed"])}
                step={33}
                onChange={controllerSpeed}
                tooltip={{
                  formatter: null,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "108px",
  },
  cardErv: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    // alignItems: "flex-start",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
  },
  cardInerv: {
    // width: "90%",
    // width: "100%",
    // height: "62px",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#FBFBFB",
    padding: "15px 15px 0 15px",
  },
  fontInpage: {
    color: "#3EA8B8",
    // textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  fontHead: {
    fontFamily: "Gotham",
    color: "#3EA8B8",
    textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
    // marginLeft: "25px",
  },
  fontInCard: {
    color: "#3EA8B8",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
    // marginLeft: "10%",
  },
  fontNumber: {
    color: "#56585D",
    fontFamily: "Gotham",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "32px",
    letterSpacing: "0.15px",
    textAlign: "center",
    marginTop: "10px",
  },
  textMode: {
    color: "#56585D",
    marginTop: "10px",
    textAlign: "center",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px" /* 175% */,
  },
  cardMode: {
    height: "105px",
    // width: "93px",
    textAlign: "center",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#FBFBFB",
    paddingTop: "15%",
  },
  cardModeAction: {
    height: "105px",
    // width: "93px",
    textAlign: "center",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "rgba(235, 244, 250, 1)",
    paddingTop: "15%",
  },
};
